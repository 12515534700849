<template>
	<!-- 房地产分类 -->
	<div class="home_page">
		<!-- <el-backtop :bottom="100">
			<div class="scoll_top" id="scollTop">
				<img src="../../assets/home_icons/top.png">
			</div>
		</el-backtop> -->

		<div class="top_fix">
			<div class="top">
				<loginHead></loginHead>
			</div>
			<div class="home_top">
				<homeTop></homeTop>
			</div>
		</div>
		<div class="main">
				<router-view />
		</div>

		<div class="bottom">
			<loginBottom></loginBottom>
		</div>
		<type-filter></type-filter>

	</div>
</template>

<script>
import loginHead from '@/components/loginHead.vue';
import homeTop from '@/components/homeTop.vue';
import loginBottom from '@/components/loginBottom.vue';
import router from '../../router';

export default {
	components: {
		loginHead,
		homeTop,
		loginBottom,
		router
	},
	data() {
		return {
			classifyStatus: 1,
			scrollTop: '', //距离顶部距离
		}
	},
	mounted() {
		// this.classifyStatus = this.$on('classifyStatus')
		// console.log(this.classifyStatus,'this.classifyStatus');
	},

	methods: {
		chaangeClassifyStatus(data) {
			this.classifyStatus = data
			console.log(data, 'data');
		}
	}
}
</script>

<style scoped="" lang="less">
.top_fix {
	width: 100%;
	position: fixed;
	top: 0;
	z-index: 500;
}

.main {
	position: relative;
	padding-top: 150px;
	width: 100%;
	height: 100%;

}

.bottom {
	height: 100%;

}



.scoll_top {
	position: fixed;
	bottom: 50px;
	right: 100px;

	img {
		width: 112px;
		height: 112px;
	}
}

/deep/ .el-backtop {
	background-color: transparent;
	width: 0;
	height: 0;
}

.home_page {
	background-color: #F6F7F9;
}

.home_page:after {
	/*添加一个内容*/
	content: "";
	/*转换为一个块元素*/
	display: block;
	/*清除两侧的浮动*/
	clear: both;
}
</style>
